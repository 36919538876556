// @ts-nocheck

import React, {useEffect} from 'react';
import {useStyles} from './Styles';
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import {post, getAll} from "../../../../../Services/GenericApiService";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {log} from "../../../../../Services/LoggerService";

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import {
    Button,
    FormControl,
    TextField,
    FormControlLabel,
    Checkbox,
    FormHelperText,
    InputAdornment, IconButton
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Select, {SelectChangeEvent} from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import {DatePicker} from '@mui/lab';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import {library} from '@fortawesome/fontawesome-svg-core';
import {getUserData, isAdmin} from "../../../../../Services/LocalStorageService";
import {encrypt} from '../../../../../Services/CryptoService'

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)


// @ts-ignore
function UserForm({handleAddEvent, handleCancelEvent, isLoading, isEditMode, recordToUpdate, handleEditEvent}) {
    // css for module
    const classes = useStyles();

    // router object
    const navigate = useNavigate();

    // module(s) for api
    const moduleMain = 'user';

    // linked components API modules (endpoints)
    const moduleRole = 'role';
    const moduleClient = 'client';

    // translation
    const {t} = useTranslation();

    // UI methods
    const [loading, setLoading] = React.useState(true);
    const [isPasswordEditable, setIsPasswordEditable] = React.useState(false);

    // form data
    const {register, handleSubmit, watch, reset, setValue, formState: {errors}} = useForm(); // watch is used to get the value e.g watch("name")

    // form methods
    const onSubmit = (_data: any) => {
        let dataObj = {..._data}

        if (isPasswordEditable) {
            if (!_data.password || _data.password == '' || _data.password == ' ')
                delete dataObj.password

            dataObj.password = encrypt(dataObj.password)
        } 
        else delete dataObj.password

        if (!isEditMode) {
            handleAddEvent(dataObj);
        } else {
            handleEditEvent(dataObj);
        }
    }

    // data vars
    const [isCheckedIsactive, setIsCheckedIsactive] = React.useState(false);
    const [allRoles, setAllRoles] = React.useState([]);
    const [allClients, setAllClients] = React.useState([]);


    // UI vars
    const [showPassword, setShowPassword] = React.useState(false);

    // ui handlers
    const onCancel = () => {
        handleCancelEvent();
    }
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    // fetch dependent data
    const fetchRoles = () => {
        setLoading(true); // show loader
        getAll(moduleRole)
            .then((_res: any) => {
                setAllRoles(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    const fetchClients = () => {
        setLoading(true); // show loader
        getAll(moduleClient)
            .then((_res: any) => {
                setAllClients(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }


    const fetch = () => {
        fetchRoles();
        fetchClients();
    }


    // hooks
    useEffect(() => {
        fetch();
        setIsPasswordEditable(true)
        if (isEditMode) {
            setIsPasswordEditable(false)
            reset(recordToUpdate);
            setIsCheckedIsactive(recordToUpdate.isActive);
        }
    }, []);


    return (
        <Box sx={{display: 'flex'}}>
            <Card sx={{minWidth: 275}}>
                {/* Form start */}
                <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
                    <CardContent>
                        <div className="py-2 px-md-4 px-2">
                            <div className="row">

                                <div className="col-md-12 my-2">
                                    {/* Form name */}
                                    <h3 className="pb-2">
                                        {isEditMode ? t('common.edit') : t('common.add')} {t('module.user')}
                                    </h3>

                                </div>


                                {/* Field firstName */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="firstNameInput"
                                                   {...register("firstName", {required: true})}
                                                   label={t('user.firstName')}
                                                   type={"text"}
                                                   error={!!errors.firstName}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field middleName */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="middleNameInput"
                                                   {...register("middleName", {required: false})}
                                                   label={t('user.middleName')}
                                                   type={"text"}
                                                   error={!!errors.middleName}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field lastName */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="lastNameInput"
                                                   {...register("lastName", {required: true})}
                                                   label={t('user.lastName')}
                                                   type={"text"}
                                                   error={!!errors.lastName}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field email */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="emailInput"
                                                   {...register("email", {required: true})}
                                                   label={t('user.email')}
                                                   type={"email"}
                                                   error={!!errors.email}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>


                                {/* Field contact */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="contactInput"
                                                   {...register("contact", {required: true})}
                                                   label={t('user.contact')}
                                                   type={"number"}
                                                   error={!!errors.contact}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field roleId */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="userIdLabel">
                                            Role
                                        </InputLabel>
                                        {allRoles.length > 0 &&
                                            <Select id="roleIdInput"
                                                    {...register("roleId", {required: false})}
                                                    label={t('user.roleId')}
                                                    value={watch('roleId')}
                                                    error={!!errors.roleId}>
                                                {
                                                    allRoles.map((el: any) =>
                                                        <MenuItem value={el.id}
                                                                  key={el.id}> {el.title}
                                                        </MenuItem>)
                                                }
                                            </Select>
                                        }
                                        {allRoles.length == 0 &&
                                            <>
                                                <Select labelId="roleIdInput"
                                                        disabled
                                                        id="roleIdInput"
                                                        {...register("roleId", {required: false})}
                                                        label={t('user.roleId')}
                                                        error={!!errors.roleId}>
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>
                                </div>

                                {/* Field workableHours */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="workableHoursInput"
                                            {...register("workableHours", { required: false })}
                                            label={t('user.workableHours')}
                                            type={"number"}
                                            error={!!errors.workableHours}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Field isActive */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth error={!!errors.isActive}>
                                        <FormControlLabel
                                            {...register("isActive", {})}
                                            label={t('user.isActive')}
                                            control={<Checkbox checked={isCheckedIsactive}
                                                               onChange={(_event: React.ChangeEvent<HTMLInputElement>) => {
                                                                   setIsCheckedIsactive(_event.target.checked)
                                                                   setValue("isActive", _event.target.checked);
                                                               }}/>}
                                        />
                                    </FormControl>
                                </div>

                                {/* Field checkIsPasswordEditable */}
                                {isAdmin(getUserData().roleId) &&
                                    <>
                                        <div className="col-md-6 mt-4">
                                            <div className="row">

                                                <div className="col-md-12">
                                                    <FormControl >
                                                        <FormControlLabel
                                                            value={isPasswordEditable}
                                                            label={'Update Password'}
                                                            control={<Checkbox checked={isPasswordEditable}
                                                                onChange={(_event: React.ChangeEvent<HTMLInputElement>) => {
                                                                    setIsPasswordEditable(!isPasswordEditable)
                                                                    if (!_event.target.checked) setValue("password", '');
                                                                }} />}
                                                        />
                                                    </FormControl>
                                                </div>

                                                <div className="col-md-12">
                                                    {/* Field password */}
                                                    {isPasswordEditable && isAdmin(getUserData().roleId) &&
                                                        <FormControl fullWidth>
                                                            <TextField id="passwordInput"
                                                                {...register("password", { required: true })}
                                                                label={t('user.password')}
                                                                type={showPassword ? "text" : "password"}
                                                                error={!!errors.password}
                                                                variant="outlined"
                                                                className="full-width"
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <IconButton
                                                                                aria-label="toggle password visibility"
                                                                                onClick={handleClickShowPassword}
                                                                            >
                                                                                {showPassword ? <FontAwesomeIcon icon='eye' /> :
                                                                                    <FontAwesomeIcon icon='eye-slash' />}
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    )
                                                                }} />
                                                        </FormControl>}
                                                </div>
                                            </div>
                                        </div>
                                    </>}


                            </div>
                        </div>
                    </CardContent>
                    <CardActions>
                        <div className="pb-4 pt-2 px-md-4 px-2 " style={{width: '100%', textAlign: 'right'}}>

                            {/* Cancel Button */}
                            <Button variant="outlined"
                                    onClick={onCancel}>
                                {t('common.cancel')}
                            </Button>

                            &nbsp;&nbsp;

                            {/* Okay Button */}
                            <LoadingButton loading={isLoading}
                                           type="submit"
                                           variant="contained"
                                           className="bg-custom-gradient">
                                {isEditMode ? t('common.edit') : t('common.add')}
                            </LoadingButton>
                            &nbsp;
                        </div>
                    </CardActions>
                </form>
            </Card>
        </Box>
    );
}

export default UserForm;
