import React, {useEffect} from 'react';
import {useStyles} from './Styles';
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import {post, getAll} from "../../../../../Services/GenericApiService";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {log} from "../../../../../Services/LoggerService";

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import {Button, FormControl, TextField, FormControlLabel, Checkbox, FormHelperText} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Select, {SelectChangeEvent} from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import {DatePicker} from '@mui/lab';
import { getUserData, isAdmin } from "../../../../../Services/LocalStorageService";
import FileUploadM from "../../../../Helpers/FileUploadM/FileUploadM";
import { Height } from '@mui/icons-material';

// @ts-ignore
function TaskForm({handleAddEvent, handleCancelEvent, isLoading, isEditMode, recordToUpdate, handleEditEvent}) {
    // css for module
    const classes = useStyles();

    // router object
    const navigate = useNavigate();

    // module(s) for api
    const moduleMain = 'task';

    // linked components API modules (endpoints)
    const moduleUser = 'user';
    const moduleProject = 'project';
    const moduleTasktype = 'tasktype';
    const moduleClient = 'client';
    let isSaveAndAddNew = false;

    const userData = getUserData();
    // @ts-ignore
    let isAdminUser = isAdmin(userData['roleId']);

    // translation
    const {t} = useTranslation();

    // UI methods
    const [loading, setLoading] = React.useState(true);

    // form data
    const {register, handleSubmit, watch, reset, setValue, formState: {errors}} = useForm(); // watch is used to get the value e.g watch("name")

    // form methods
    const onSubmit = (_data: any) => {
        if (!isEditMode) {
            handleAddEvent(_data, isSaveAndAddNew);
        } else {
            handleEditEvent(_data);
        }
        resetForm();
    }

    // data vars
    const [selectedTaskdate, setSelectedTaskdate] = React.useState<Date | null>(null);
    const [selectedClientId, setSelectedClientId] = React.useState([]);


    // ui handlers
    const onCancel = () => {
        handleCancelEvent();
    }

    const onClientSelectChange = (_e: any) => {
        fetchProjectByClientId(_e);
    }

    const setSaveAndAddNew = () => {
        isSaveAndAddNew = true;
    }

    const unsetSaveAndAddNew = () => {
        isSaveAndAddNew = false;
    }

    const resetForm = () => {
        setValue('hours', '');
        setValue('comments', '');
        setTaskTypeId(0);
        setTaskDate(new Date());
    }

    const resetSelectDropDown = (elementId: string) => {
        let element = document.getElementById(elementId);
        // @ts-ignore
        element.value = 0;
         // @ts-ignore
        element.textContent = '';
    }
    // fetch dependent data
    const [allUser, setAllUser] = React.useState([]);
    const [allProject, setAllProject] = React.useState([]);
    const [allTasktype, setAllTasktype] = React.useState([]);
    const [allClients, setAllClients] = React.useState([]);
    const [taskDate, setTaskDate] = React.useState(new Date());
    const [clientId, setClientId] = React.useState(0);
    const [projectId, setProjectId] = React.useState(0);
    const [taskTypeId, setTaskTypeId] = React.useState(0);

    const fetch = () => {
        fetchUser();
        fetchTasktype();
        fetchClients();
    }

    // fetch User
    const fetchUser = () => {
        setLoading(true); // show loader
        getAll(moduleUser)
            .then((_res: any) => {
                setAllUser(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // fetch Project
    const fetchProjectByClientId = (_clientId: any) => {
        setLoading(true); // show loader
        getAll(moduleProject + '/client/' + _clientId)
            .then((_res: any) => {
                setAllProject(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // fetch Tasktype
    const fetchTasktype = () => {
        setLoading(true); // show loader
        getAll(moduleTasktype)
            .then((_res: any) => {
                setAllTasktype(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // fetch Clients
    const fetchClients = () => {
        setLoading(true); // show loader
        getAll(moduleClient)
            .then((_res: any) => {
                setAllClients(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }


    // hooks
    useEffect(() => {
        fetch();
        if (isEditMode) {
            reset(recordToUpdate);

            setSelectedTaskdate(recordToUpdate.taskDate);
            setClientId(recordToUpdate.taskProject.clientId);
            onClientSelectChange(recordToUpdate.taskProject.clientId);
            setProjectId(recordToUpdate.projectId);
            setTaskDate(recordToUpdate.taskDate);
            setTaskTypeId(recordToUpdate.taskTypeId);
        }
    }, []);

    return (
        <div className='overflow-scroll' style={{maxHeight: '100vh'}}>
            <Box sx={{display: 'flex'}}>
                <Card sx={{minWidth: 275}}>
                    {/* Form start */}
                    <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
                        <CardContent>
                            <div className="py-2 px-md-4 px-2">
                                <div className="row">

                                    <div className="col-md-12 my-2">
                                        {/* Form name */}
                                        <h3 className="pb-2">
                                            {isEditMode ? t('common.edit') : t('common.add')} {t('module.task')}
                                        </h3>

                                    </div>


                                    {/* Field userId */}
                                    <div className="col-md-6 mt-4">
                                        <FormControl fullWidth={true}>
                                            <InputLabel id="userIdLabel">
                                                User
                                            </InputLabel>
                                            {allUser.length > 0 &&
                                                <Select labelId="userIdLabel"
                                                        id="userIdSelect"
                                                        {...register("userId", {required: true})}
                                                        error={!!errors.userId}
                                                        value={watch('userId')}
                                                        // @ts-ignore
                                                        defaultValue={userData['id']}
                                                        label="userId">
                                                    {
                                                        isAdminUser ? allUser.map((el: any) =>
                                                            <MenuItem value={el.id}
                                                                    key={el.id}> {el.email} </MenuItem>)
                                                                    // @ts-ignore
                                                                    : <MenuItem value= {userData.id}
                                                                        // @ts-ignore
                                                                        key={userData.id}> {userData.email}
                                                                        </MenuItem>
                                                    }
                                                </Select>
                                            }
                                            {allUser.length == 0 &&
                                                <>
                                                    <Select labelId="locationIdLabel"
                                                            disabled
                                                            id="userIdSelect"
                                                            {...register("userId", {required: true})}
                                                            error={!!errors.userId}
                                                            value={watch('userId')}
                                                            label="userId">
                                                        <MenuItem value={''}>
                                                            No Data
                                                        </MenuItem>
                                                    </Select>
                                                    <FormHelperText>
                                                        No Data
                                                    </FormHelperText>
                                                </>
                                            }
                                        </FormControl>
                                    </div>

                                    {/* Field client */}
                                    <div className="col-md-6 mt-4">
                                        <FormControl fullWidth={true}>
                                            <InputLabel id="taskTypeIdLabel">
                                                Client
                                            </InputLabel>
                                            {allClients.length > 0 &&
                                                <Select labelId="clientLabel"
                                                        id="clientSelect"
                                                        value={clientId != 0 ? clientId : null}
                                                        defaultValue={clientId != 0 ? clientId : null}
                                                        {...register("clientId", {required: true})}
                                                        onChange={(event: any) => {
                                                            onClientSelectChange(event.target.value)
                                                            setClientId(parseInt(event.target.value))
                                                        }}
                                                        error={!!errors.clientId}
                                                        label="client">
                                                    {
                                                        allClients.map((el: any) =>
                                                            <MenuItem value={el.id}
                                                                    key={el.id}> {el.name}
                                                            </MenuItem>)
                                                    }
                                                </Select>
                                            }
                                            {allClients.length == 0 &&
                                                <>
                                                    <Select disabled
                                                            labelId="clientLabel"
                                                            id="clientSelect"
                                                            {...register("clientId", {required: true})}
                                                            error={!!errors.clientId}
                                                            label="client">
                                                        <MenuItem value={''}>
                                                            No Data
                                                        </MenuItem>
                                                    </Select>
                                                    <FormHelperText>
                                                        No Data
                                                    </FormHelperText>
                                                </>
                                            }
                                        </FormControl>
                                    </div>


                                    {/* Field projectId */}
                                    <div className="col-md-6 mt-4">
                                        <FormControl fullWidth={true}>
                                            <InputLabel id="projectIdLabel">
                                                Project
                                            </InputLabel>
                                            {allProject.length > 0 &&
                                                <Select labelId="projectIdLabel"
                                                        id="projectIdSelect"
                                                        defaultValue={projectId != 0 ? projectId : null}
                                                        {...register("projectId", {required: true})}
                                                        onChange={(event: any) => {
                                                            setProjectId(parseInt(event.target.value))
                                                        }}
                                                        error={!!errors.projectId}
                                                        value={projectId != 0 ? projectId : null}
                                                        label="projectId">
                                                    {
                                                        allProject.map((el: any) =>
                                                            <MenuItem value={el.id}
                                                                    key={el.id}> {el.name}
                                                            </MenuItem>)
                                                    }
                                                </Select>
                                            }
                                            {allProject.length == 0 &&
                                                <>
                                                    <Select labelId="locationIdLabel"
                                                            disabled
                                                            id="projectIdSelect"
                                                            {...register("projectId", {required: true})}
                                                            error={!!errors.projectId}
                                                            value={watch('projectId')}
                                                            label="projectId">
                                                        <MenuItem value={''}>
                                                            No Data
                                                        </MenuItem>
                                                    </Select>
                                                    <FormHelperText>
                                                        No Data
                                                    </FormHelperText>
                                                </>
                                            }
                                        </FormControl>
                                    </div>

                                    {/* Field taskTypeId */}
                                    <div className="col-md-6 mt-4">
                                        <FormControl fullWidth={true}>
                                            <InputLabel id="taskTypeIdLabel">
                                                Task Type
                                            </InputLabel>
                                            {allTasktype.length > 0 &&
                                                <Select labelId="taskTypeIdLabel"
                                                        id="taskTypeIdSelect"
                                                        {...register("taskTypeId", {required: true})}
                                                        onChange={(event: any) => {
                                                            setTaskTypeId(parseInt(event.target.value))
                                                        }}
                                                        error={!!errors.taskTypeId}
                                                        value={taskTypeId != 0 ? taskTypeId : null}
                                                        label="taskTypeId">
                                                    {
                                                        allTasktype.map((el: any) =>
                                                            <MenuItem value={el.id}
                                                                    key={el.id}> {el.name}
                                                            </MenuItem>)
                                                    }
                                                </Select>
                                            }
                                            {allTasktype.length == 0 &&
                                                <>
                                                    <Select labelId="locationIdLabel"
                                                            disabled
                                                            id="taskTypeIdSelect"
                                                            {...register("taskTypeId", {required: true})}
                                                            error={!!errors.taskTypeId}
                                                            value={watch('taskTypeId')}
                                                            label="taskTypeId">
                                                        <MenuItem value={''}>
                                                            No Data
                                                        </MenuItem>
                                                    </Select>
                                                    <FormHelperText>
                                                        No Data
                                                    </FormHelperText>
                                                </>
                                            }
                                        </FormControl>
                                    </div>

                                    {/* Field taskDate */}
                                    <div className="col-md-6 mt-4">
                                        <FormControl fullWidth>
                                            <DatePicker
                                                label={t('task.taskDate')}
                                                value={taskDate}
                                                onChange={(_newValue: any) => {
                                                    setSelectedTaskdate(_newValue);
                                                    setValue("taskDate", _newValue, {
                                                        shouldValidate: true,
                                                        shouldDirty: true
                                                    });
                                                    setTaskDate(_newValue);
                                                }}
                                                renderInput={(params: any) => (
                                                    <TextField {...params}
                                                            {...register("taskDate", {required: true})}
                                                            error={!!errors.taskDate}
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </div>

                                    {/* Field hours */}
                                    <div className="col-md-6 mt-4">
                                        <FormControl fullWidth>
                                            <TextField id="hoursInput"
                                                    {...register("hours", {required: true})}
                                                    label={t('task.hours')}
                                                    defaultValue={isEditMode ? recordToUpdate.hours : null}
                                                    type={"float"}
                                                    error={!!errors.hours}
                                                    variant="outlined"
                                                    className="full-width"/>
                                        </FormControl>
                                    </div>

                                    {/* Field comments */}
                                    <div className="col-md-12 mt-4">
                                        <FormControl fullWidth>
                                            <TextField id="commentsInput"
                                                    {...register("comments", {required: false})}
                                                    label={t('task.comments')}
                                                    type={"text"}
                                                    defaultValue={isEditMode ? recordToUpdate.comments : null}
                                                    error={!!errors.comments}
                                                    variant="outlined"
                                                    className="full-width"
                                                    multiline
                                                    rows={4}/>
                                        </FormControl>
                                    </div>

                                    {/* File Uploading */}
                                    <div className="col-md-12 mt-5">
                                        <FileUploadM/>
                                    </div>

                                </div>
                            </div>
                        </CardContent>
                        <CardActions>
                            <div className="pb-4 pt-2 px-md-4 px-2 " style={{width: '100%', textAlign: 'right'}}>

                                {/* Save and Add new Button */}
                                {isEditMode == false &&
                                    <LoadingButton loading={isLoading}
                                                type="submit"
                                                variant="contained"
                                                onClick={setSaveAndAddNew}
                                                className="bg-custom-gradient">
                                        Save & Add New
                                    </LoadingButton>
                                }
                                &nbsp;&nbsp;

                                {/* Cancel Button */}
                                <Button variant="outlined"
                                        onClick={onCancel}>
                                    {t('common.cancel')}
                                </Button>

                                &nbsp;&nbsp;

                                {/* Okay Button */}
                                <LoadingButton loading={isLoading}
                                            type="submit"
                                            variant="contained"
                                            onClick={unsetSaveAndAddNew}
                                            className="bg-custom-gradient">
                                    {isEditMode ? t('common.edit') : t('common.add')}
                                </LoadingButton>
                                &nbsp;
                            </div>
                        </CardActions>
                    </form>
                </Card>
            </Box>
        </div>
    );
}

export default TaskForm;
