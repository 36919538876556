// @ts-nocheck
import React, {useEffect} from 'react';
import {useStyles} from './Styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Header from "../../../Layout/Header/Header";
import Sider from "../../../Layout/Sider/Sider";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {getAll} from "../../../../Services/GenericApiService";
import {log} from "../../../../Services/LoggerService";
import TextDisplay from "../Chart/TextDisplay/TextDisplay";
import BarChart from "../Chart/BarChart/BarChart";
import DoughnutChart from '../Chart/DoughnutChart/DoughnutChart';
import LineChart from '../Chart/LineChart/LineChart';
import {getUserData} from "../../../../Services/LocalStorageService";
import MUIDataTable from "mui-datatables";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import Chip from '@mui/material/Chip';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {library} from '@fortawesome/fontawesome-svg-core';
import {DateRangePicker, DateRange} from "mui-daterange-picker";
import StackedBarChart from "../Chart/StackedBarChart/StackedBarChart";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import UserDataChart from '../Chart/UserDataChart/UserDataChart';
import {formatData} from "../../../../Services/DataFormatService";
import {Typography, Divider, Slide} from '@mui/material';

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function Home() {
    // css for module
    const classes = useStyles();

    // router object
    const navigate = useNavigate();

    // module(s) for api
    const moduleMain = 'dashboard';

    // translation
    const {t} = useTranslation();

    // UI methods
    const [loading, setLoading] = React.useState(true);
    const toggleDatePicker = () => setOpenDatePicker(!openDatePicker);
    const [dataViewMode, setDataViewMode] = React.useState<string | null>('total');
    const [openDatePicker, setOpenDatePicker] = React.useState(false);

    // data vars
    const [allTiles, setAllTiles] = React.useState({});
    const [displayData, setDisplayData] = React.useState([]);   // store the data that is being displayed after applying filters
    const [taskByUsersAndClientsDataTable, setTaskByUsersAndClientsDataTable] = React.useState({});
    const [dateRange, setDateRange] = React.useState<DateRange<Dayjs>>([null, null]);
    const [hoursOfUsersByClientsChart, setHoursOfUsersByClientsChart] = React.useState({});

    // hooks
    useEffect(() => {
        fetch();
    }, []);

    useEffect(() => {
        extractHoursOfUsersByClientsChartData();
    }, [displayData]);

    useEffect(() => {
        extractDisplayData(null, true);
    }, [allTiles]);

    useEffect(() => {
        if (dateRange.startDate && dateRange.endDate) {
            fetchDateRangeTiles();
        }
    }, [dateRange]);

    // date picker
    const renderDatePicker = () => {
        return (
            <DateRangePicker
                open={openDatePicker}
                toggle={toggleDatePicker}
                onChange={(range) => {
                    setDateRange(range);
                    toggleDatePicker();
                }}
            />
        );
    }

    /** Prepares Date Range Query Params */
    const getDatesQueryParams = () => {

        const start = new Date(dateRange.startDate)
        start.setDate(start.getDate() + 1)
        const sd = start.getUTCDate();
        const sm = start.getUTCMonth() + 1;
        const sy = start.getUTCFullYear()

        const end = new Date(dateRange.endDate)
        const ed = end.getUTCDate();
        const em = end.getUTCMonth() + 1;
        const ey = end.getUTCFullYear()

        return {sd, sm, sy, ed, em, ey}
    }

    // fetch data
    const userData = getUserData();
    const fetchDateRangeTiles = () => {
        if (dateRange.startDate && dateRange.endDate) {
            /** Prepares url Query Params for date range */
            const {sd, sm, sy, ed, em, ey} = getDatesQueryParams();
            let urlWithQuery = `${moduleMain}/tiles?sd=${sd}&sm=${sm}&sy=${sy}&ed=${ed}&em=${em}&ey=${ey}`;
            getAll(urlWithQuery)
                .then((_res: any) => {
                    setAllTiles(_res);
                    setTaskByUsersAndClientsDataTable({
                        columns: ['Task Type', 'Client', 'Fund', ' User', {
                            name: 'Hours',
                            options: {filter: false}
                        }, {
                            name: 'Date',
                            options: {filter: false}
                        }],
                        data: _res.taskHoursByClientsAndUsersData.map(_task => {
                            return formatData([_task.taskType, _task.client, _task.fund, _task.user, _task.hours, _task.date])
                        })
                    })
                })
                .catch(_err => {
                    log(_err)
                    setLoading(false); // hide loader
                })
        } else {
            fetch();
        }
    }

    const fetchTiles = () => {
        setLoading(true); // show loader
        getAll(moduleMain + '/tiles')
            .then((_res: any) => {
                setAllTiles(_res)
                setTaskByUsersAndClientsDataTable({
                    columns: ['Task Type', 'Client', 'Fund', ' User', {
                        name: 'Hours',
                        options: {filter: false}
                    }, {
                        name: 'Date',
                        options: {filter: false}
                    }],
                    data: _res.taskHoursByClientsAndUsersData.map(_task => {
                        return formatData([_task.taskType, _task.client, _task.fund, _task.user, _task.hours, _task.date])
                    })
                })
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })

    }

    // User Table Data
    let userRows = [];

    const fetch = () => {
        fetchTiles();
    }


    const handleDataViewModeToggle = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string | null,
    ) => {
        if (newAlignment != null) {
            setDataViewMode(newAlignment);
        }
    };

    // data table options
    const dataTableOptions = {
        filterType: 'checkbox',
        selectableRowsHideCheckboxes: false,
        selectableRows: 'none',
        elevation: 0,
        onTableChange: (action: any, state: any) => {
            switch (action) {
                case 'filterChange':
                    setLoading(true);
                    extractDisplayData(state);
                    setLoading(false);
                    return;
                case 'search':
                    return;
                case 'propsUpdate':
                    return;
            }
        },
        downloadOptions: {
            filename: 'Workload_Breakdown_' + new Date().toDateString() + '.csv',
            filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true
            }
        }
    };


    const extractDisplayData = (_data, showAllFlag = false) => {
        let filteredData = [];
        let clientFilterArr = [];
        let userFilterArr = [];

        try {
            clientFilterArr = _data.filterList[1].map(_client => _client.toLowerCase());
            userFilterArr = _data.filterList[3].map(_user => _user.toLowerCase());
        } catch (e) {

        }


        // default view
        if (showAllFlag && Object.keys(allTiles).length > 0) {
            allTiles.taskHoursByClientsAndUsersData.forEach(_rec => {
                filteredData.push(_rec);
            })
        } else if (!showAllFlag && Object.keys(allTiles).length > 0) {

            // filter view
            allTiles.taskHoursByClientsAndUsersData.forEach(_rec => {
                if (clientFilterArr.includes(_rec.client)
                    || userFilterArr.includes(_rec.user)
                    || (userFilterArr.length == 0 && clientFilterArr.length == 0)) {
                    filteredData.push(_rec);
                }
            })
        }

        setDisplayData(filteredData);
    }

    const extractHoursOfUsersByClientsChartData = () => {
        let selectedClients = [];
        let datasets = [];
        let hoursObj = {};

        // restructure to store hours in following format
        // hoursObj.user.client.hours

        // if data is loaded
        if (displayData.length > 0) {
            displayData.forEach(_rec => {
                // add client to selectedClients (labels array)
                if (!selectedClients.includes(_rec.client)) {
                    selectedClients.push(_rec.client);
                }

                // restructured hours object
                if (!hoursObj.hasOwnProperty(_rec.user)) {
                    // if no key for user, create one
                    hoursObj[_rec.user] = {};
                }

                if (!hoursObj[_rec.user].hasOwnProperty(_rec.client)) {
                    // if no key for user, create one
                    hoursObj[_rec.user][_rec.client] = {};
                    hoursObj[_rec.user][_rec.client]['hours'] = 0;
                }

                // add hours
                hoursObj[_rec.user][_rec.client]['hours'] += parseFloat(_rec.hours);
            })
        }

        selectedClients.sort(); // sort to get the same order

        // prepare dataset
        Object.keys(hoursObj).forEach(_user => {
            let hoursOfUser = [];

            selectedClients.forEach(_client => {
                if (hoursObj[_user].hasOwnProperty(_client)) {
                    // push hours
                    hoursOfUser.push(parseFloat(hoursObj[_user][_client].hours));
                } else {
                    // if no hours, push zero
                    hoursOfUser.push(0);
                }
            })

            datasets.push({
                label: _user,
                data: hoursOfUser
            })
        })

        setHoursOfUsersByClientsChart({
            labels: selectedClients,
            dataset: datasets
        })

    }


    function createData(email: string, hours: number, remainingHours: number, overtimeHours: number, workableHours) {
        remainingHours = (workableHours * 4) - hours;
        overtimeHours = hours - (workableHours * 4);
        return {email, hours, remainingHours, overtimeHours};
    }

    // User Table Data
    let userChartData = allTiles.usersChartData;
    let allUserEmails = userChartData ? userChartData.userEmails : [];

    for (var i = 0; i < allUserEmails.length; i++) {
        userRows.push(createData(allUserEmails[i], userChartData.userHours[i], userChartData.userRemainingHours[i], userChartData.userOvertimeHours[i], userChartData.allUsersWorkableHours[i]));
    }

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>

            <Header/>

            <Sider/>


            {/* content */}
            <Box component="main" sx={{flexGrow: 2, p: 3}} mt={10}>
                <div class='row'>
                    <div className="col">
                        <h1 className={'fw-bolder py-3'}>
                            Dashboard
                        </h1>
                    </div>
                    <div className="col-md-4">
                        <button variant="outlined"
                                onClick={toggleDatePicker}
                                className={'my-2 py-3 col-12 rounded'}
                                fullWidth>
                            {!dateRange.startDate &&
                                <span>
                                    Select Date Range
                                </span>
                            }
                            {dateRange.startDate &&
                                <span>
                                    Select {dateRange.startDate?.toLocaleDateString()} {'➝'} {dateRange.endDate?.toLocaleDateString()}
                                </span>
                            }
                        </button>
                    </div>

                    {/* Show Date Picker */}
                    {openDatePicker &&
                        <>
                            <Slide direction="up" in={openDatePicker} mountOnEnter unmountOnExit>
                                <div className="row my-5 d-flex justify-content-center">
                                    <div className="col-lg-6 col-md-9 col-sm-12 my-auto">
                                        {renderDatePicker()}
                                    </div>

                                </div>
                            </Slide>
                        </>
                    }
                </div>
                {/*  tiles at top  */}
                {Object.keys(allTiles).length > 0 && !openDatePicker &&
                    <>
                        <Slide direction="up" in={!openDatePicker} mountOnEnter unmountOnExit>
                            <div>
                                {/*  tiles at top  */}
                                <div className="row">
                                    <div className="col-md-4 mt-3">
                                        <TextDisplay label={'Available Hours Per Month'}
                                                     data={allTiles.availableHours}/>
                                    </div>

                                    <div className="col-md-4 mt-3">
                                        <TextDisplay label={'Hours Logged This Month'}
                                                     data={allTiles.hoursLoggedThisMonth}/>
                                    </div>

                                    <div className="col-md-4 mt-3">
                                        <TextDisplay label={'Utilisation'} data={allTiles.utilization}/>
                                    </div>

                                    <div className="col-md-4 mt-3">
                                        <TextDisplay label={'Active Clients'} data={allTiles.activeClients}/>
                                    </div>

                                    <div className="col-md-4 mt-3">
                                        <TextDisplay label={'Active Projects'} data={allTiles.activeProjects}/>
                                    </div>

                                    <div className="col-md-4 mt-3">
                                        <TextDisplay label={'Task Logged This Month'}
                                                     data={allTiles.taskLoggedThisMonth}/>
                                    </div>
                                </div>

                                <div className="row mt-5">
                                    {/* Data Table */}
                                    <div class="col-md-12">
                                        <div className="card shadow-lg p-4 overflow-scroll" style={{height: '90vh'}}>
                                            <Typography variant="h5">
                                                Workload Breakdown
                                            </Typography>
                                            <div className="pt-2">
                                                <Divider/>
                                            </div>
                                            <MUIDataTable
                                                data={taskByUsersAndClientsDataTable.data}
                                                columns={taskByUsersAndClientsDataTable.columns}
                                                options={dataTableOptions}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* Hours By Clients Chart */}
                                <div className="row mt-5">
                                    <div class='col'>
                                        <div className="card shadow-lg p-4 text-black">
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <div className={'py-2 mb-3 fw-bolder'}>
                                                        {dataViewMode == 'total' &&
                                                            <>
                                                                <Typography variant="h5">
                                                                    Total Hours Structure Per Client
                                                                </Typography>
                                                                <div className="pt-2">
                                                                    <Divider/>
                                                                </div>
                                                            </>
                                                        }
                                                        {dataViewMode == 'breakdown' &&
                                                            <>
                                                                <Typography variant="h5">
                                                                    Hours Structure Per User Per Client
                                                                </Typography>
                                                                <div className="pt-2">
                                                                    <Divider/>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </div>

                                                {/* Toggle for total vs per client breakdown */}
                                                <div className="col-md-4">
                                                    <ToggleButtonGroup
                                                        value={dataViewMode}
                                                        exclusive
                                                        fullWidth
                                                        onChange={handleDataViewModeToggle}
                                                        aria-label="text alignment"
                                                    >
                                                        <ToggleButton value="total" aria-label="left aligned">
                                                            <span>
                                                                Total Hours
                                                            </span>
                                                        </ToggleButton>
                                                        <ToggleButton value="breakdown" aria-label="centered">
                                                            <span>
                                                                User Hours
                                                            </span>
                                                        </ToggleButton>
                                                    </ToggleButtonGroup>
                                                </div>
                                            </div>


                                            {/*  Bar Charts  */}
                                            {dataViewMode == 'total' &&
                                                <BarChart dataset={[allTiles.hoursByClientData.dataset]}
                                                          XAxisLabel={'Clients'}
                                                          YAxisLabel={'Hours'}
                                                          height={'55vh'}
                                                          width={'90vw'}
                                                          labels={allTiles.hoursByClientData.labels}
                                                />
                                            }


                                            {dataViewMode == 'breakdown' &&
                                                <StackedBarChart
                                                    dataset={hoursOfUsersByClientsChart.dataset}
                                                    labels={hoursOfUsersByClientsChart.labels}
                                                    axis={'x'}
                                                    height={'55vh'}
                                                    width={'90vw'}
                                                    legendPosition={'bottom'}
                                                    XAxisLabel={'Clients'}
                                                    YAxisLabel={'Hours'}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-5 text-black">
                                    {/* Hours Logged By User */}
                                    <div class='col-md-6'>
                                        <div className="card shadow-lg" style={{height: '35em'}}>
                                            <UserDataChart title='Logged Hours per User'
                                                           data={userRows}>
                                            </UserDataChart>
                                        </div>
                                    </div>

                                    {/* Utilisation % Chart */}
                                    <div class='col-md-6'>
                                        <div className="card shadow-lg p-4" style={{height: '35em'}}>
                                            <LineChart dataset={[{
                                                label: 'Utilisation',
                                                data: allTiles.utilisationLineChartData.data,
                                                fill: false,
                                                borderColor: 'rgb(75, 192, 192)',
                                                tension: 0.1
                                            }]}
                                                       axisLabel="%"
                                                       labels={allTiles.utilisationLineChartData.labels}
                                                       title={'Utilisation Percentage'}/>
                                        </div>
                                    </div>
                                </div>


                                <div className="row mt-5 text-black">
                                    {/* Workload by task type Chart */}
                                    <div class="col-md-12">
                                        <div className="card shadow-lg p-4 overflow-scroll" style={{height: '90vh'}}>
                                            <BarChart dataset={[allTiles.allTaskHoursByTaskTypeData.dataset]}
                                                      YAxisLabel={'Hours'}
                                                      XAxisLabel={'Task Types'}
                                                      height={'65vh'}
                                                      axis={'x'}
                                                      labels={allTiles.allTaskHoursByTaskTypeData.labels}
                                                      title={'Workload By Task Type'}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5 text-black">
                                    {/* Hours By Pillars */}
                                    <div class='col-md-5'>
                                        <div className="card shadow-lg p-4 my-auto">
                                            <DoughnutChart dataset={[allTiles.hoursByFunds.data]}
                                                           axisLabel="hours"
                                                           height={'50vh'}
                                                           totalHours={allTiles.hoursByFunds.totalFundsHours}
                                                           labels={allTiles.hoursByFunds.labels}
                                                           title={'Hours by Pillars'}/>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                    </div>
                                </div>
                            </div>
                        </Slide>
                    </>
                }
            </Box>
        </Box>
    );
}

export default Home;
