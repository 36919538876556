// @ts-nocheck

import React, {useEffect, useRef} from 'react';
import Box from '@mui/material/Box';
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';
import {Doughnut} from 'react-chartjs-2';
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Typography, Divider } from '@mui/material';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

function DoughnutChart({dataset = null, labels = null, title = '', axisLabel = '', height = '', width = '', totalHours = null}) {
    const bgColor = [
        "rgba(0, 0, 255, 1)",
        "rgba(0, 153, 255, 1)",
        "rgba(0, 102, 102, 1)",
        "rgba(0, 204, 204, 1)",
        "rgba(102, 102, 255, 1)",
        "rgba(153, 153, 255, 1)",
        "rgba(204, 204, 255, 1)",
        "rgba(0, 102, 255, 1)",
        "rgba(153, 204, 255, 1)",
        "rgba(0, 153, 153, 1)",
        "rgba(0, 51, 51, 1)",
        "rgba(51, 204, 255, 1)",
        "rgba(102, 204, 255, 1)",
        "rgba(3, 248, 252)",
        "rgba(3, 115, 252)",
        "rgba(134, 173, 240)",
        "rgba(86, 55, 153)",
        "rgba(221, 159, 237)",
        "rgba(105, 184, 139)",
        "rgba(166, 196, 118)",
        "rgba(232, 172, 210)",
        "rgba(146, 101, 191)",
        "rgba(96, 66, 168)",
        "rgba(151, 132, 245)",
        "rgba(102, 90, 166)",
        "rgba(90, 124, 166)",
        "rgba(153, 204, 255, 1)",
        "rgba(204, 204, 255, 1)"
    ];

    const data = {
        labels: labels,
        datasets: [
            {
                label: 'title',
                data: dataset ? dataset[0] : [],
                backgroundColor: bgColor,
                hoverOffset: 4,
                height: '300px'
            },
        ],
    };

    const options = {
        // maintainAspectRatio: false,
        responsive: true,
        plugins: {
            tooltip: {
                callbacks: {
                    label: (ttItem) => (`${ttItem.parsed}` + axisLabel)
                }
            },
            datalabels: {
                color: '#fff',
                display: function(context) {
                    return context.dataset.data[context.dataIndex] > 0; // or >= 1 or ...
                }
            },
            legend: {
                position: 'bottom' as const,
                // align: 'start',
                labels: {
                    usePointStyle: true,
                    boxWidth: 10,
                    color: '#000',
                }
            },
        },
    };

    const plugins = [{
        beforeDraw: function(chart) {
         var width = chart.width,
             height = chart.height,
             ctx = chart.ctx;
             ctx.restore();
             var fontSize = (height / 500).toFixed(2);
             ctx.font = fontSize + "em Roboto";
             ctx.textBaseline = "center";
             var text = "Total Hours: " + totalHours,
             textX = Math.round((width - ctx.measureText(text).width) / 2),
             textY = height / 2;
             ctx.fillText(text, textX, textY);
             ctx.save();
        }
      }]

    return (
        <div className={'mb-4'}>
            {dataset!=null && labels!=null &&
                <>
                    
                    <Typography variant="h5">
                        {title}
                    </Typography>
                    <div className="pt-2 pb-3">
                        <Divider />
                    </div>

                    <div className="p-0 d-flex justify-content-center align-items-center" style={{ width, height }}>
                        {Math.max(...dataset) === 0 &&
                            <h5 className={'py-2 mb-5 text-center'}>No Data</h5>
                        }

                        {Math.max(...dataset) !== 0 &&
                                <Doughnut data={data} options={options} plugins={ plugins }/>
                        }
                    </div>
                </>
            }
        </div>
    );

}

export default DoughnutChart;
