// @ts-nocheck

import React, {useEffect, useState, useRef} from 'react';
import {useStyles} from './Styles';
import '../../../../../index.scss';
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {getAll, getById, post, patch, deleteById} from "../../../../../Services/GenericApiService";
import {formatData} from "../../../../../Services/DataFormatService";
import {useTranslation} from "react-i18next";
import AlertM from '../../../../Helpers/AlertM/AlertM';
import TaskCreate from "../TaskCreate/TaskCreate";
import TaskUpdate from "../TaskUpdate/TaskUpdate";
import TaskView from "../TaskView/TaskView";
import {log} from "../../../../../Services/LoggerService";

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import MUIDataTable from "mui-datatables";
import {Button, IconButton, Skeleton} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import {library} from '@fortawesome/fontawesome-svg-core';
import Tooltip from '@mui/material/Tooltip';
import ConfirmationDialog from "../../../../Helpers/ConfirmationDialog/ConfirmationDialog";
import { getUserData, isAdmin } from "../../../../../Services/LocalStorageService";
import { DatePicker } from "@mui/lab";
import {
    FormGroup,
    FormLabel,
    FormControl,
    ListItemText,
    TextField,
    Checkbox,
    FormControlLabel,
    Grid,
    Select,
    InputLabel,
    MenuItem
} from '@mui/material';

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function TaskMain() {
    // user data
    const userData = getUserData();

    // css for module
    const classes = useStyles();

    // translation
    const {t} = useTranslation();

    // child ref for alert
    const notifications = useRef();

    // module(s) for api
    const moduleMain = 'task';

    // data vars
    const [allData, setAllData] = React.useState([]);
    const [dataToEdit, setDataToEdit] = React.useState({});

    let columns;
    if (isAdmin(userData.roleId)) {
        columns = [
            {name: "User", options: { filter: true, sort: true, filterOptions: { fullWidth: true} }},
            {name: "Client", options: { filter: true, sort: true, filterType: 'multiselect', filterOptions: { fullWidth: true} }},
            {name: "Project", options: { filter: true, sort: true, filterType: 'multiselect', filterOptions: { fullWidth: true} }},
            {name: "Task Type", options: { filter: true, sort: true, filterType: 'multiselect', filterOptions: { fullWidth: true} }},
            {
                name: 'Task Date',
                options: {
                  filter: true,
                  filterType: 'custom',

                  customFilterListOptions: {
                    render: v => {
                      if (v[0] && v[1]) {
                        return [`Start Date: ${v[0]}`, `End Date: ${v[1]}`];
                      } else if (v[0]) {
                        return `Start Date: ${v[0]}`;
                      } else if (v[1]) {
                        return `End Date: ${v[1]}`;
                      }
                      return [];
                    },
                    update: (filterList, filterPos, index) => {
                      console.log('customFilterListOnDelete: ', filterList, filterPos, index);

                      if (filterPos === 0) {
                        filterList[index].splice(filterPos, 1, '');
                      } else if (filterPos === 1) {
                        filterList[index].splice(filterPos, 1);
                      } else if (filterPos === -1) {
                        filterList[index] = [];
                      }

                      return filterList;
                    },
                  },
                  filterOptions: {
                    names: [],
                    logic(date, filters) {
                        let dateConverted = new Date(date);
                        if (filters[0] != null && filters[1] != null) {
                            return dateConverted < filters[0] || dateConverted > filters[1];
                        } else if (filters[0]) {
                            return dateConverted < filters[0];
                        } else if (filters[1]) {
                            return dateConverted > filters[1];
                        }
                        return false;
                    },
                    display: (filterList, onChange, index, column) => (
                      <div>
                        <FormLabel>Task Date</FormLabel>
                        <FormGroup row>
                          <DatePicker
                            renderInput={(params: any) => (
                                <TextField {...params}
                                />
                            )}
                            label='Start Date'
                            value={filterList[index][0] || null}
                            onChange={(_newValue: any) => {
                              filterList[index][0] = _newValue;
                              onChange(filterList[index], index, column);
                            }}
                            style={{ width: '45%', marginRight: '5%' }}
                          />
                          <DatePicker
                            renderInput={(params: any) => (
                                <TextField {...params}
                                />
                            )}
                            label='End Date'
                            value={filterList[index][1] || null}
                            onChange={(_newValue) => {
                              filterList[index][1] = _newValue;
                              onChange(filterList[index], index, column);
                            }}
                            style={{ width: '45%' }}
                          />
                        </FormGroup>
                      </div>
                    ),
                  },
                  print: false,
                },
            },
            {name: "Hours", options: { filter: false, sort: true, filterOptions: { fullWidth: true} }},
            {name: "Comments", options: { filter: false, sort: true, filterOptions: { fullWidth: true} }},
        ]
    } else {
        columns = [
            {name: "Client", options: { filter: true, sort: true, filterType: 'multiselect', filterOptions: { fullWidth: true} }},
            {name: "Project", options: { filter: true, sort: true, filterType: 'multiselect', filterOptions: { fullWidth: true} }},
            {name: "Task Type", options: { filter: true, sort: true, filterType: 'multiselect', filterOptions: { fullWidth: true} }},
            {
                name: 'Task Date',
                options: {
                  filter: true,
                  filterType: 'custom',

                  customFilterListOptions: {
                    render: v => {
                      if (v[0] && v[1]) {
                        return [`Start Date: ${v[0]}`, `End Date: ${v[1]}`];
                      } else if (v[0]) {
                        return `Start Date: ${v[0]}`;
                      } else if (v[1]) {
                        return `End Date: ${v[1]}`;
                      }
                      return [];
                    },
                    update: (filterList, filterPos, index) => {
                      console.log('customFilterListOnDelete: ', filterList, filterPos, index);

                      if (filterPos === 0) {
                        filterList[index].splice(filterPos, 1, '');
                      } else if (filterPos === 1) {
                        filterList[index].splice(filterPos, 1);
                      } else if (filterPos === -1) {
                        filterList[index] = [];
                      }

                      return filterList;
                    },
                  },
                  filterOptions: {
                    names: [],
                    logic(date, filters) {
                        let dateConverted = new Date(date);
                        if (filters[0] != null && filters[1] != null) {
                            return dateConverted < filters[0] || dateConverted > filters[1];
                        } else if (filters[0]) {
                            return dateConverted < filters[0];
                        } else if (filters[1]) {
                            return dateConverted > filters[1];
                        }
                        return false;
                    },
                    display: (filterList, onChange, index, column) => (
                      <div>
                        <FormLabel>Task Date</FormLabel>
                        <FormGroup row>
                          <DatePicker
                            renderInput={(params: any) => (
                                <TextField {...params}
                                />
                            )}
                            label='Start Date'
                            value={filterList[index][0] || null}
                            onChange={(_newValue: any) => {
                              filterList[index][0] = _newValue;
                              onChange(filterList[index], index, column);
                            }}
                            style={{ width: '45%', marginRight: '5%' }}
                          />
                          <DatePicker
                            renderInput={(params: any) => (
                                <TextField {...params}
                                />
                            )}
                            label='End Date'
                            value={filterList[index][1] || null}
                            onChange={(_newValue) => {
                              filterList[index][1] = _newValue;
                              onChange(filterList[index], index, column);
                            }}
                            style={{ width: '45%' }}
                          />
                        </FormGroup>
                      </div>
                    ),
                  },
                  print: false,
                },
            },
            {name: "Hours", options: { filter: false, sort: true, filterOptions: { fullWidth: true} }},
            {name: "Comments", options: { filter: false, sort: true, filterOptions: { fullWidth: true} }},
        ]
    }
    columns.push({
        name: "",
        options: {
            filter: false,
            sort: false,
            empty: true,
            setCellHeaderProps: () => { return { align: "right" } },
            setCellProps: () => ({
                align: "right"
            }),
            customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
                return (
                    <div className='d-inline-flex'>
                        <div className="px-2">
                            <Tooltip title={t('common.edit')}>
                                <IconButton aria-label="delete" size="small" onClick={() => {
                                        onEditClick(dataIndex, rowIndex)
                                    }}>
                                    <FontAwesomeIcon className="edit-btn" icon='edit'/>
                                </IconButton>
                            </Tooltip>
                        </div>

                        <div className="px-2">
                                {/*  delete confirmation dialog  */}
                                <ConfirmationDialog title={'Delete Confirmation'}
                                                    body={'Are you sure you want to delete this record?'}
                                                    type={'delete'}
                                                    isLoading={loading}
                                                    dataItemIdx={dataIndex}
                                                    confirmEvent={handleDelete}/>
                        </div>

                            <div className="px-2">
                            {allData &&
                                <TaskView data={allData[dataIndex]} />
                            }
                        </div>

                    </div>
                )
                    ;
            }
        }
    });
    const [tableData, setTableData] = React.useState([]);

    // ui controls
    const [loading, setLoading] = React.useState(true);
    const [editMode, setEditMode] = React.useState(false);

    // table ui controls
    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("400px");
    const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
    const [searchBtn, setSearchBtn] = useState(true);
    const [downloadBtn, setDownloadBtn] = useState(true);
    const [printBtn, setPrintBtn] = useState(true);
    const [viewColumnBtn, setViewColumnBtn] = useState(true);
    const [filterBtn, setFilterBtn] = useState(true);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);

    // fetch dependent data
    const fetch = () => {
        setTableData([]);  // reset table data
        setLoading(true); // show loader

        let url = moduleMain;

        // fetch everything for admin but if not admin, fetch only tasks of that user
        if(!isAdmin(userData.roleId)){
            url += '/user/' + userData.id;
        }

        getAll(url, currentPage)
            .then((_res: any) => {
                // set all data
                setAllData(_res)

                // set total count
                setTotalRowCount(_res.length);

                const rows: any = [];

                _res.forEach((_record: any) => {
                    if (isAdmin(userData.roleId)){
                        rows.push(formatData([_record?.taskUser?.email, _record?.taskProject?.projectClient.name, _record?.taskProject?.name, _record?.taskTaskType?.name, _record?.taskDate, _record?.hours, _record?.comments]))
                    } else {
                        rows.push(formatData([_record?.taskProject?.projectClient.name, _record?.taskProject?.name, _record?.taskTaskType?.name, _record?.taskDate, _record?.hours, _record?.comments]))
                    }
                });

                setTableData(rows);
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    useEffect(() => {
        fetch();
    }, [currentPage, pageSize])

    // event handlers
    const onEditClick = (_dataIndex: any, _rowIndex: any) => {
        setDataToEdit(allData[_dataIndex]);
        setEditMode(true);
    }

    const resetEdit = (_state) => {
        setEditMode(false);
    }

    const handleDelete = (_dataItemIndex: number) => {
        setLoading(true);   // show spinner
        deleteById(moduleMain, allData[_dataItemIndex].id)
            .then(async (_res) => {
                setLoading(false);  // hide loader
                // @ts-ignore
                notifications.current.successAlert(t('common.deleteSuccess'), '');
                fetch();    // refresh data
            })
            .catch(_err => {
                log(_err)
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                setLoading(false); // hide loader
            })
    }


    let options = {
        search: true,
        download: true,
        print: true,
        viewColumns: true,
        filter: true,
        filterType: "textField",
        responsive: "simple",
        selectableRowsHideCheckboxes: true,
        sort: true,
        loading: true,
        rowsPerPageOptions: [25, 50, 75, 100],
        rowsPerPage: pageSize,
        textLabels: {
            body: {
                noMatch: loading ?
                    <Box>
                        <Skeleton className="my-4"/>
                        <Skeleton className="my-4"/>
                        <Skeleton className="my-4"/>
                    </Box> :
                    'Sorry, there is no matching data to display',
            },
        },
        onTableChange: (action: any, state: any) => {
            // console.log(action);
            // console.dir(state);
        }
    };

  if (tableData.length === 0) {
        options = {
            textLabels: {
                body: {
                    noMatch: loading ?
                        <Box>
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                        </Box> :
                        'Sorry, there is no matching data to display',
                },
            },
        };
    }

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>

            {/* Header */}
            <Header/>

            {/* Sider */}
            <Sider/>

            {/* Content */}
            <Box component="main" sx={{flexGrow: 2, p: 3}} mt={10}>
                <div className="px-1 px-md-5">

                    {/* Upper Section */}
                    <div className="row">
                        <div className="col-md-10">
                            <h2 className="mt-3">
                            {t('module.task')}
                            </h2>
                        </div>
                        <div className="col-md-2">
                            <TaskCreate refreshDataEvent={fetch}/>
                        </div>
                    </div>

                    <div className="mt-5">
                        <Paper elevation={12}>
                            <MUIDataTable
                                title={t('common.allRecords')}
                                data={tableData}
                                columns={columns}
                                // @ts-ignore
                                options={options}
                            />
                        </Paper>
                    </div>

                </div>

                {/* display edit modal */}
                {editMode &&
                    <div>
                        <TaskUpdate refreshDataEvent={fetch}
                                        resetEditState={resetEdit}
                                        recordToUpdate={dataToEdit}/>
                    </div>
                }


                 {/* Alerts */}
                <AlertM ref={notifications}/>

            </Box>


        </Box>
    );
}

export default TaskMain;
