// @ts-nocheck
import React, {useEffect, useRef} from 'react';
import Box from '@mui/material/Box';
import {Typography, Divider} from '@mui/material';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {Bar} from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);


function BarChart({dataset = null, labels = null, title = '', axis = 'x', YAxisLabel = '', XAxisLabel = '', height = '', width = ''}) {

    const borderColor = [
        "rgba(0, 0, 255, 1)",
        "rgba(0, 153, 255, 1)",
        "rgba(0, 102, 102, 1)",
        "rgba(0, 204, 204, 1)",
        "rgba(102, 102, 255, 1)",
        "rgba(153, 153, 255, 1)",
        "rgba(204, 204, 255, 1)",
        "rgba(0, 102, 255, 1)",
        "rgba(153, 204, 255, 1)",
        "rgba(0, 153, 153, 1)",
        "rgba(0, 51, 51, 1)",
        "rgba(51, 204, 255, 1)",
        "rgba(102, 204, 255, 1)",
        "rgba(3, 248, 252)",
        "rgba(3, 115, 252)",
        "rgba(134, 173, 240)",
        "rgba(86, 55, 153)",
        "rgba(221, 159, 237)",
        "rgba(105, 184, 139)",
        "rgba(166, 196, 118)",
        "rgba(232, 172, 210)",
        "rgba(146, 101, 191)",
        "rgba(96, 66, 168)",
        "rgba(151, 132, 245)",
        "rgba(102, 90, 166)",
        "rgba(90, 124, 166)",
        "rgba(153, 204, 255, 1)",
        "rgba(204, 204, 255, 1)"
    ];
    const bgColor = ['rgba(0, 130, 200, 0.5)', 'rgba(60, 180, 75, 0.5)', 'rgba(255, 225, 25, 0.5)', 'rgba(230, 25, 75, 0.5)', 'rgba(245, 130, 48, 0.5)', 'rgba(145, 30, 180, 0.5)', 'rgba(70, 240, 240, 0.5)', 'rgba(240, 50, 230, 0.5)', 'rgba(210, 245, 60, 0.5)', 'rgba(250, 190, 212, 0.5)', 'rgba(0, 128, 128, 0.5)', 'rgba(220, 190, 255, 0.5)', 'rgba(170, 110, 40, 0.5)', 'rgba(255, 250, 200, 0.5)', 'rgba(128, 0, 0, 0.5)', 'rgba(170, 255, 195, 0.5)', 'rgba(128, 128, 0, 0.5)', 'rgba(255, 215, 180, 0.5)', 'rgba(0, 0, 128, 0.5)', 'rgba(128, 128, 128, 0.5)', 'rgba(255, 255, 255, 0.5)', 'rgba(0, 0, 0, 0.5)'];

    const data = {
        labels,
        datasets: dataset,
    };


    const options = {
        indexAxis: axis as const,
        maintainAspectRatio: height=='' && width=='',     // should be true if height and width are empty strings (i.e default)
        scales: {
            x: {
                barPercentage: 0.5,
                title: {
                    display: true,
                    text: XAxisLabel,
                }
            },
            y: {
                title: {
                    display: true,
                    text: YAxisLabel,
                }
            },
        },
        plugins: {
            datalabels: {
                color: '#fff',
                display: function(context) {
                    return context.dataset.data[context.dataIndex] > 0; // or >= 1 or ...
                }
            },
            legend: {
                position: 'bottom' as const,
                labels: {
                    usePointStyle: true,
                    boxWidth: 10
                }
            },},
    };



    // add colors
    if(data.datasets != null && data.datasets.length > 0){
        for(let i=0; i<data.datasets.length; i++){
            data.datasets[i]["backgroundColor"] = borderColor;
        }
    }

    return (
        <div className={'mb-4'}>
            <Typography variant="h5">
                {title}
            </Typography>
            <div className="pt-2">
                <Divider/>
            </div>
            <div className={'mb-4'} style={{ width, height }}>
                {/*
            // @ts-ignore */}
                <Bar options={options} data={data} style={{display: 'inline'}}/>
            </div>
        </div>
    );

}

export default BarChart;
