import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import {isAdmin, isLoggedIn} from "../../../Services/LocalStorageService";
import {getUserData} from "../../../Services/LocalStorageService";
const adminRoutes= ['role', 'dashboard', 'fund', 'client', 'tasktype', 'task']

export function AuthGuard({route = ''}) {
    const auth = isLoggedIn(); // determine if authorized, from context or however you're doing it

    const loggedInUser: any = getUserData()

    if (adminRoutes.includes(route)) {
        /** checks and allows requested route if logged in user is admin rights, otherwise re-routes it to task page */
        if (isAdmin(loggedInUser.roleId)) {
            return auth ? <Outlet /> : <Navigate to="/login" />;
        }
        else return <Navigate to="/task" />
    }

    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return auth ? <Outlet /> : <Navigate to="/login" />;
}