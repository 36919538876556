// @ts-nocheck
import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Typography, Divider } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Chip from '@mui/material/Chip';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


function UserDataChart({ data = {}, title = '' }) {

    return (
        <div className="card shadow-lg p-4" style={{height: '35em'}}>
            <Typography variant="h5">
                {title}
            </Typography>
            <div className="pt-2">
                <Divider />
            </div>
            <TableContainer>
                <Table sx={{minWidth: 400, maxHeight: 400}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className='text-black'>User</TableCell>
                            <TableCell className='text-black'
                                        align="right">Hours&nbsp;</TableCell>
                            <TableCell className='text-black' align="right">Remaining
                                Hours</TableCell>
                            <TableCell className='text-black' align="right">Overtime
                                Hours</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row) =>
                            <TableRow
                                key={row.name}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell component="th" scope="row" className='text-black'>
                                    {row.email}
                                </TableCell>
                                <TableCell className='text-black'
                                           align="right">{row.hours}</TableCell>
                                <TableCell className='text-black'
                                           align="right">
                                    {row.remainingHours > 0 &&
                                        <Chip icon={ <FontAwesomeIcon className={'ps-2 pe-1'} icon='caret-down'/>} color="warning" label={row.remainingHours} />
                                    }
                                    {row.remainingHours == 0 &&
                                        <Chip icon={ <FontAwesomeIcon className={'ps-2 pe-1'} icon='check'/>} color="success" label={'0'} />
                                    }
                                </TableCell>
                                <TableCell className='text-black' align="right">
                                    {row.overtimeHours > 0 &&
                                        <Chip icon={ <FontAwesomeIcon className={'ps-2 pe-1'} icon='caret-up'/>} color="secondary" label={row.overtimeHours} />
                                    }
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );

}

export default UserDataChart;
