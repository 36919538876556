// @ts-nocheck
import React, {useEffect, useRef} from 'react';
import Box from '@mui/material/Box';
import {Line} from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    ScriptableContext
} from "chart.js";
import { Typography, Divider } from '@mui/material';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);



function LineChart({dataset = null, labels = null, title = '', axisLabel = '', height = '', width = ''}) {

    const borderColor = [
        "rgba(0, 0, 255, 1)",
        "rgba(0, 153, 255, 1)",
        "rgba(0, 102, 102, 1)",
        "rgba(0, 204, 204, 1)",
        "rgba(102, 102, 255, 1)",
        "rgba(153, 153, 255, 1)",
        "rgba(204, 204, 255, 1)",
        "rgba(0, 102, 255, 1)",
        "rgba(153, 204, 255, 1)",
        "rgba(0, 153, 153, 1)",
        "rgba(0, 51, 51, 1)",
        "rgba(51, 204, 255, 1)",
        "rgba(102, 204, 255, 1)",
        "rgba(3, 248, 252)",
        "rgba(3, 115, 252)",
        "rgba(134, 173, 240)",
        "rgba(86, 55, 153)",
        "rgba(221, 159, 237)",
        "rgba(105, 184, 139)",
        "rgba(166, 196, 118)",
        "rgba(232, 172, 210)",
        "rgba(146, 101, 191)",
        "rgba(96, 66, 168)",
        "rgba(151, 132, 245)",
        "rgba(102, 90, 166)",
        "rgba(90, 124, 166)",
        "rgba(153, 204, 255, 1)",
        "rgba(204, 204, 255, 1)"
    ];
    const bgColor = ['rgba(0, 0, 255, 0.2)', 'rgba(60, 180, 75, 0.2)', 'rgba(255, 225, 25, 0.2)', 'rgba(0, 130, 200, 0.2)', 'rgba(245, 130, 48, 0.2)', 'rgba(145, 30, 180, 0.2)', 'rgba(70, 240, 240, 0.2)', 'rgba(240, 50, 230, 0.2)', 'rgba(210, 245, 60, 0.2)', 'rgba(250, 190, 212, 0.2)', 'rgba(0, 128, 128, 0.2)', 'rgba(220, 190, 255, 0.2)', 'rgba(170, 110, 40, 0.2)', 'rgba(255, 250, 200, 0.2)', 'rgba(128, 0, 0, 0.2)', 'rgba(170, 255, 195, 0.2)', 'rgba(128, 128, 0, 0.2)', 'rgba(255, 215, 180, 0.2)', 'rgba(0, 0, 128, 0.2)', 'rgba(128, 128, 128, 0.2)', 'rgba(255, 255, 255, 0.2)', 'rgba(0, 0, 0, 0.2)'];

    const options = {
        maintainAspectRatio: height=='' && width=='',     // should be true if height and width are empty strings (i.e default)
        fill: true,
        plugins: {
            datalabels: {
                color: '#000',
                display: function(context) {
                    return context.dataset.data[context.dataIndex] > 0; // or >= 1 or ...
                }
            },
            tooltip: {
                callbacks: {
                    label: function(context) {
                        let label = context.dataset.label || '';

                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += context.parsed.y + ' ' + axisLabel;
                        }
                        return label;
                    }
                }
            },
            legend: {
                position: 'bottom' as const,
            }
        },
    };

    const data = {
        labels,
        datasets: [],
    };

    if (dataset != null) {
        dataset.forEach((_record, _i) => {
            data.datasets.push({
                label: _record.label,
                data: _record.data,
                fill: "start",
                backgroundColor: (context: ScriptableContext<"line">) => {
                    const ctx = context.chart.ctx;
                    const gradient = ctx.createLinearGradient(0, 0, 0, 200);
                    gradient.addColorStop(0, borderColor[_i]);
                    gradient.addColorStop(1, bgColor[_i]);
                    return gradient;
                },
                borderColor: borderColor[_i]
            });
        })
    }

    return (
        <div className={'mb-4'}>

            <Typography variant="h5">
                {title}
            </Typography>
            <div className="pt-2 pb-3">
                <Divider />
            </div>

            {dataset != null && labels != null &&
                <div className={'mb-4'} style={{ width, height }}>
                    <Line options={options} data={data} />
                </div>
            }

        </div>
    );

}

export default LineChart;
