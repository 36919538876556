// @ts-nocheck

import React, {useEffect} from 'react';
import {useStyles} from './Styles';
import Box from '@mui/material/Box';
import Tooltip from "@mui/material/Tooltip";
import {Button, Card, CardContent, IconButton} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import {library} from '@fortawesome/fontawesome-svg-core';
import {useTranslation} from "react-i18next";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import CardActions from "@mui/material/CardActions";
import DataDisplayM from "../../../../Helpers/DataDisplayM/DataDisplayM";
import {log} from "../../../../../Services/LoggerService";
import {getAll, getById, post, patch, deleteById} from "../../../../../Services/GenericApiService";
import {formatData} from "../../../../../Services/DataFormatService";

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function TaskView({data = null}) {
    // css for module
    const classes = useStyles();

    // module(s) for api
    const moduleMain = 'task';

    // data vars
    const [allFiles, setAllFiles] = React.useState({});

    // translation
    const {t} = useTranslation();

    // fetch dependent data
    const fetchFiles = (_id) => {
        setLoading(true); // show loader
        let url = moduleMain + '/files/' + _id;

        getAll(url)
            .then((_res: any) => {
                // set all data
                setAllFiles(_res)
                console.log(_res);
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }


    // ui handlers
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    // handle cancel event
    const handleCancelEvent = () => {
        handleClose();
    }

    // hooks
    useEffect(() => {
        fetchFiles(data.id);
    }, []);


    return (
        <div>
            <Box sx={{display: 'flex'}}>
                <Tooltip title={t('common.view')}>
                    <IconButton aria-label="delete" size="small" onClick={handleOpen}>
                        <FontAwesomeIcon className="edit-btn" icon='eye'/>
                    </IconButton>
                </Tooltip>
            </Box>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box className={classes.modal}>
                        <Card sx={{minWidth: '75vw'}}>
                            <CardContent>
                                <div className="py-4 px-md-4 px-0">
                                    <h1 className="mb-3">{t('common.recordDetails')}</h1>
                                    <div className="row full-width">


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('task.userId')} data={data.taskUser?.email}/>
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('task.projectId')}
                                                              data={data.taskProject?.name}/>
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('task.taskTypeId')}
                                                              data={data.taskTaskType?.name}/>
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('task.taskDate')} data={data.taskDate}/>
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('task.hours')} data={data.hours}/>
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('task.comments')} data={data.comments}/>
                                            }
                                        </div>

                                        <div className="col-md-12">
                                            {Object.keys(allFiles).length == 0 &&
                                                <DataDisplayM label={'Files'} data={'No Files Attached'}/>
                                            }
                                            {Object.keys(allFiles).length > 0 &&
                                                <>
                                                    <DataDisplayM label={'Files'} data={''}/>
                                                    {
                                                        allFiles.files.map(_file => (
                                                            <>
                                                        <span>
                                                             ■ &nbsp;
                                                            <a href={process.env.REACT_APP_SERVER_ROOT + allFiles.basePath + _file}
                                                               target={"_blank"}>
                                                            {_file}
                                                        </a>
                                                               </span>
                                                                <br/>
                                                            </>
                                                        ))
                                                    }
                                                </>
                                            }
                                        </div>


                                    </div>
                                </div>
                            </CardContent>
                            <CardActions>
                                <div className="pb-4 pt-2 px-md-4 px-2 " style={{width: '100%', textAlign: 'right'}}>

                                    {/* Cancel Button */}
                                    <Button variant="outlined"
                                            onClick={handleCancelEvent}>
                                        {t('common.cancel')}
                                    </Button>

                                    &nbsp;&nbsp;
                                </div>
                            </CardActions>
                        </Card>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}

export default TaskView;
