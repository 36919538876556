// @ts-nocheck
import React, {useEffect, useRef} from 'react';
import { Typography } from '@mui/material';

function TextDisplay({label = '', data = '', background = 'dark'}) {

    return (
        <div className={'card shadow-lg bg-' + background}>
            <div className={'alert m-0 p-0 py-2 px-3'}>
                
                <Typography variant="subtitle1" sx={{color: 'white'}}>
                {label}
                </Typography>
                
                <h3 className={'fw-bolder text-white'}>
                    {data}
                </h3>
            </div>
        </div>
    );

}

export default TextDisplay;
