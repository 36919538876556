import React, {useEffect} from 'react';
import {useStyles} from './Styles';
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import {post, getAll} from "../../../../../Services/GenericApiService";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {log} from "../../../../../Services/LoggerService";

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import {Button, FormControl, TextField, FormControlLabel, Checkbox, FormHelperText} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Select, {SelectChangeEvent} from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import {DatePicker} from '@mui/lab';
import {formatData} from "../../../../../Services/DataFormatService";


// @ts-ignore
function ClientForm({handleAddEvent, handleCancelEvent, isLoading, isEditMode, recordToUpdate, handleEditEvent}) {
    // css for module
    const classes = useStyles();

    // router object
    const navigate = useNavigate();

    // module(s) for api
    const moduleMain = 'client';

    // linked components API modules (endpoints)
    const moduleFunds = 'fund';

    // data vars
    const [fundsData, setFundsData] = React.useState([]);


    // translation
    const {t} = useTranslation();

    // UI methods
    const [loading, setLoading] = React.useState(true);

    // form data
    const {register, handleSubmit, watch, reset, setValue, formState: {errors}} = useForm(); // watch is used to get the value e.g watch("name")

    // form methods
    const onSubmit = (_data: any) => {
        if (!isEditMode) {
            handleAddEvent(_data);
        } else {
            handleEditEvent(_data);
        }
    }

    // data vars
    const [isCheckedIsactive, setIsCheckedIsactive] = React.useState(false);


    // ui handlers
    const onCancel = () => {
        handleCancelEvent();
    }


    const fetch = () => {
        fetchFunds();
    }

    // fetch dependent data
    const fetchFunds = () => {
        getAll(moduleFunds)
            .then((_res: any) => {
                // set all funds
                setFundsData(_res);
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }


    // hooks
    useEffect(() => {
        fetch();
        if (isEditMode) {
            reset(recordToUpdate);
            setIsCheckedIsactive(recordToUpdate.isActive);
        }
    }, []);


    return (
        <Box sx={{display: 'flex'}}>
            <Card sx={{minWidth: 275}}>
                {/* Form start */}
                <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
                    <CardContent>
                        <div className="py-2 px-md-4 px-2">
                            <div className="row">

                                <div className="col-md-12 my-2">
                                    {/* Form name */}
                                    <h3 className="pb-2">
                                        {isEditMode ? t('common.edit') : t('common.add')} {t('module.client')}
                                    </h3>

                                </div>


                                {/* Field name */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="nameInput"
                                                   {...register("name", {required: true})}
                                                   label={t('client.name')}
                                                   type={"text"}
                                                   error={!!errors.name}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field isActive */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth error={!!errors.isActive}>
                                        <FormControlLabel
                                            {...register("isActive", {})}
                                            label={t('client.isActive')}
                                            control={<Checkbox checked={isCheckedIsactive}
                                                               onChange={(_event: React.ChangeEvent<HTMLInputElement>) => {
                                                                   setIsCheckedIsactive(_event.target.checked)
                                                                   setValue("isActive", _event.target.checked);
                                                               }}/>}
                                        />
                                    </FormControl>
                                </div>



                                {/* Field fundId */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="fundIdLabel">
                                            Funds
                                        </InputLabel>
                                        {  fundsData.length > 0 &&
                                            <Select labelId="fundIdLabel"
                                                    id="fundIdSelect"
                                                    {...register("fundId", {required: true})}
                                                    error={!!errors.fundId}
                                                    value={watch('fundId')}
                                                    label="fundId">
                                                {
                                                    fundsData.map((el: any) =>
                                                        <MenuItem value={el.id}
                                                                  key={el.id}> {el.name}
                                                        </MenuItem>)
                                                }
                                            </Select>
                                        }
                                        { fundsData.length == 0 &&
                                            <>
                                                <Select labelId="locationIdLabel"
                                                        disabled
                                                        id="fundIdSelect"
                                                        {...register("fundId", {required: true})}
                                                        error={!!errors.fundId}
                                                        value={watch('fundId')}
                                                        label="fundId">
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>
                                </div>


                                {/* Field addressLine1 */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="addressLine1Input"
                                                   {...register("addressLine1", {required: true})}
                                                   label={t('client.addressLine1')}
                                                   type={"text"}
                                                   error={!!errors.addressLine1}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field addressLine2 */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="addressLine2Input"
                                                   {...register("addressLine2", {required: false})}
                                                   label={t('client.addressLine2')}
                                                   type={"text"}
                                                   error={!!errors.addressLine2}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field city */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="cityInput"
                                                   {...register("city", {required: true})}
                                                   label={t('client.city')}
                                                   type={"text"}
                                                   error={!!errors.city}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field zip */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="zipInput"
                                                   {...register("zip", {required: false})}
                                                   label={t('client.zip')}
                                                   type={"text"}
                                                   error={!!errors.zip}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field country */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="countryInput"
                                                   {...register("country", {required: true})}
                                                   label={t('client.country')}
                                                   type={"text"}
                                                   error={!!errors.country}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field phone */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="phoneInput"
                                                   {...register("phone", {required: true})}
                                                   label={t('client.phone')}
                                                   type={"text"}
                                                   error={!!errors.phone}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field email */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="emailInput"
                                                   {...register("email", {required: true})}
                                                   label={t('client.email')}
                                                   type={"text"}
                                                   error={!!errors.email}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>


                            </div>
                        </div>
                    </CardContent>
                    <CardActions>
                        <div className="pb-4 pt-2 px-md-4 px-2 " style={{width: '100%', textAlign: 'right'}}>

                            {/* Cancel Button */}
                            <Button variant="outlined"
                                    onClick={onCancel}>
                                {t('common.cancel')}
                            </Button>

                            &nbsp;&nbsp;

                            {/* Okay Button */}
                            <LoadingButton loading={isLoading}
                                           type="submit"
                                           variant="contained"
                                           className="bg-custom-gradient">
                                {isEditMode ? t('common.edit') : t('common.add')}
                            </LoadingButton>
                            &nbsp;
                        </div>
                    </CardActions>
                </form>
            </Card>
        </Box>
    );
}

export default ClientForm;
