// @ts-nocheck

import React, {useEffect} from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";

// Import components
import Landing from "../Components/Screens/Landing/Landing";
import Login from "../Components/Screens/Authentication/Login/Login";
import Register from "../Components/Screens/Authentication/Register/Register";
import Home from "../Components/Screens/Dashboard/Home/Home"
import Logout from "../Components/Screens/Authentication/Logout/Logout";
import {RootStateOrAny, useSelector} from "react-redux";
import {useTranslation, Trans} from 'react-i18next';
import {AuthGuard} from "./Guards/AuthGuard/AuthGuard";
import {ThemeProvider, createTheme} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';


import RoleMain from "../Components/Screens/Dashboard/Role/RoleMain/RoleMain";
import UserMain from "../Components/Screens/Dashboard/User/UserMain/UserMain";
import ClientMain from "../Components/Screens/Dashboard/Client/ClientMain/ClientMain";
import ProjectMain from "../Components/Screens/Dashboard/Project/ProjectMain/ProjectMain";
import TasktypeMain from "../Components/Screens/Dashboard/Tasktype/TasktypeMain/TasktypeMain";
import TaskMain from "../Components/Screens/Dashboard/Task/TaskMain/TaskMain";
import {getUserData} from "../Services/LocalStorageService";
import FundMain from "../Components/Screens/Dashboard/Fund/FundMain/FundMain";
// %TEMPLATE_IMPORT_COMPONENT%









function Router() {
    const {t, i18n} = useTranslation();

    // get user data
    const userData = getUserData();
    const currentUserID = userData == null ? -1 : userData.roleId;

    useEffect(() => {
        document.title = 'Time Tracker';
    }, []);

    // get current language
    // const language = useSelector(
    //     (state: RootStateOrAny) => state.global.global.language,
    // );

    // switch language here
    // i18n.changeLanguage('en')

    // get current theme
    const theme = useSelector(
        (state: RootStateOrAny) => state.global.global.theme,
    );


    const darkTheme = createTheme({
        palette: {
            mode: theme,
               primary: {
                main: "#020024FF",
                contrastText: "#fff" //button text white instead of black to support dark theme
            },
            secondary: {
                main: "#0000FF",
                contrastText: "#fff" //button text white instead of black to support dark theme
            }
        },
        components: {
            MuiCard: {
                styleOverrides: {
                    root: {
                        borderColor: 'black',
                        border: '2px solid black',
                        borderWidth: 2,
                        "&:hover": {
                            boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)" // Add shadow on hover
                        },
                    },
                },
            },
        },
    });


    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline/>
            <BrowserRouter>
                {/*<Header/>*/}
                <Routes>
                    <Route path="/" element={<Login/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/register" element={<Register/>}/>
                    <Route path="/logout" element={<Logout/>}/>

                    <Route path="/project" element={<AuthGuard />} >
                        <Route path="/project" element={<ProjectMain />} />
                    </Route>

                    <Route path="/task" element={<AuthGuard />} >
                        <Route path="/task" element={<TaskMain />} />
                    </Route>

                    {/* Admin Role */}
                    {/*
                     // @ts-ignore */}
                    <Route path="/dashboard" element={<AuthGuard route='dashboard' />}>
                        <Route path="/dashboard" element={<Home />} />
                    </Route>

                    {/* Admin routes only */}
                    <Route path="/role" element={<AuthGuard route='role' />} >
                        <Route path="/role" element={<RoleMain />} />
                    </Route>

                    <Route path="/user" element={<AuthGuard route='user' />} >
                        <Route path="/user" element={<UserMain />} />
                    </Route>

                    <Route path="/fund" element={<AuthGuard route='fund' />} >
                        <Route path="/fund" element={<FundMain />} />
                    </Route>

                    <Route path="/client" element={<AuthGuard route='client' />} >
                        <Route path="/client" element={<ClientMain />} />
                    </Route>

                    <Route path="/tasktype" element={<AuthGuard route='tasktype' />} >
                        <Route path="/tasktype" element={<TasktypeMain />} />
                    </Route>


                {/* %TEMPLATE_INSERT_ROUTE%  */}

                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default Router;
